import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@solid-reach/accordion";
import { Show, createSignal, splitProps } from "solid-js";
import { Terms, ExperienceInfo, EducationInfo, RegionInfo, CVInfo } from ".";

export function InfoCard(props) {
  const [local, others] = splitProps(props, ["data"]);
  const [taskOpened, setTaskOpened] = createSignal(false);
  const [infoOpen, setInfoOpen] = createSignal(false);
  const [taskId, setTaskId] = createSignal(0);

  const onTaskOpen = () => {
    setTaskOpened(taskOpened() ? false : true);
  };
  const onInfoOpen = (taskID) => {
    if (taskID === taskId()) {
      setInfoOpen(infoOpen() ? false : true);
      setTaskId(taskID);
    } else {
      setInfoOpen(infoOpen() ? false : true);
      setInfoOpen(true);
      setTaskId(taskID);
    }

    console.log("taskID", taskID);
  };

  const terms = [
    {
      id: 1,
      title: "Lorem ipsum",
      description:
        "dolor sit amet, consectetur adipiscing elit. Mauris eros tellus, laoreet ut massa nec, vestibulum iaculis lectus. Mauris bibendum ultricies lorem non tincidunt. Quisque at semper ipsum. Etiam ultrices augue ut tellus dignissim, hendrerit faucibus purus ",
    },
    {
      id: 2,
      title: "tempor",
      description:
        "Nulla condimentum urna dolor, vitae scelerisque arcu interdum ut. Integer lacus eros, suscipit id mauris quis, suscipit viverra urna. Phasellus non ligula et orci finibus pulvinar quis et dolor. Sed porta, neque eu scelerisque congue, lorem odio mollis nunc, quis posuere enim eros id nisi. Maecenas sed maximus eros, non pharetra dolor. Etiam ultricies commodo lectus, non dapibus elit vestibulum vel. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis aliquet id nulla eu interdum. Vivamus ",
    },
  ];

  const agreements = [
    { id: 1, text: " Nulla condimentum urna dolor, vitae scelerisque arcu interdum ", required: true, checked: false },
  ];

  return (
    <div class="flex flex-col justify-between text-16px p-20px gap-14px ">
      {!!local.data.progress.description ? (
        <p class=" font-400 text-start pb-2">{local.data.progress.description}</p>
      ) : (
        <p class="font-400 text-start pb-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        </p>
      )}

      {Array.isArray(local.data.my_exhibition) && (
        <div class="flex flex-col gap-10px">
          <p>
            {local.data.my_exhibition.filter((task) => task.status).length} of {local.data.my_exhibition.length}{" "}
            required information submited
          </p>
          {local.data.my_exhibition.map((task, id) => {
            console.log("task.id", id);
            return (
              <>
                <div id={id.toString()} class="flex gap-10px items-center justify-between">
                  <div class="flex gap-10px">
                    <input class="w-25px h-25px" checked={task.status || task.details?.status} type="checkbox" />
                    <p class="text-14px">{task.name || task.details?.name}</p>
                  </div>
                  {(!task.status || !task.details?.status) && (
                    <p class="cursor-pointer" onClick={() => onInfoOpen(id)}>
                      {" "}
                      {"> "}
                      {infoOpen() && taskId() === id ? "Save and Close" : task.action || "> Read"}
                    </p>
                  )}
                </div>
                <Show when={infoOpen() && taskId() === id && id === 0}>
                  <Terms stage_id={others.stage_id} agreements={agreements} terms={terms} />
                </Show>
                <Show when={infoOpen() && taskId() === id && id === 1}>
                  <EducationInfo
                    stage_id={others.stage_id}
                    data={task.content}
                    onFormSubmit={undefined}
                    onBack={undefined}
                  />
                </Show>

                <Show when={infoOpen() && taskId() === id && id === 2}>
                  <RegionInfo stage_id={others.stage_id} onFormSubmit={undefined} onBack={undefined} />
                </Show>
                <Show when={infoOpen() && taskId() === id && id === 3}>
                  <ExperienceInfo
                    stage_id={others.stage_id}
                    data={task.content}
                    onFormSubmit={undefined}
                    onBack={undefined}
                  />
                </Show>
                <Show when={infoOpen() && taskId() === id && id === 4}>
                  <CVInfo stage_id={others.stage_id} onFormSubmit={undefined} onBack={undefined} />
                </Show>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}
